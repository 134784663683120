@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "parvus/dist/css/parvus.css";

@layer base {
  body {
    background-image: url("/_img/header.jpg");
    background-repeat: repeat-x;
    background-size: 2rem;
    padding-top: 2rem;
  }
}

/* a11y */
@layer base {
  *:focus {
    outline: 3px solid #f87171;
  }
  *:focus:not(:focus-visible) {
    outline: 0;
  }
  * {
    @apply
      focus-visible:outline-none
      focus-visible:ring
      focus-visible:ring-offset-2
      focus-visible:ring-red-400;
  }
}
